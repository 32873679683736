/* Enables customization of addons */
/* Helper to load variables */
#sidebar .image-wrapper {
  position: relative;
  height: 100px;
  margin-top: 1em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
}
#sidebar .image-wrapper .ui.button.remove-image {
  position: absolute;
  top: 7px;
  right: 7px;
  margin: 0;
  box-shadow: none;
}
#sidebar .image-wrapper .ui.button.remove-image:hover {
  background-color: transparent !important;
}
#sidebar .image-wrapper .ui.button.remove-image svg {
  padding: 2px;
  border: 1px solid #e4e8ec;
  margin: 0;
  background: #f3f5f7;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 8px;
  opacity: 1;
}
#sidebar .align-widget .eight.wide.column > div {
  display: flex !important;
  align-items: center;
}
#sidebar .align-widget .ui.icon.button {
  padding: 4px !important;
  margin-left: 4px !important;
  border-radius: 1px !important;
}
#sidebar .align-widget .ui.icon.button:hover {
  background-color: #edf1f2 !important;
}
#sidebar .align-widget .ui.buttons:first-child .ui.icon.button {
  margin-left: 0;
}
#sidebar .align-widget .ui.active.basic.icon.button {
  border: 1px solid #2996da;
  background: transparent !important;
}
.roundtiled {
  margin-bottom: 1rem !important;
  text-align: center;
}
.roundtiled-title {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 44px;
}
.imagecards-block .cards {
  display: flex;
  flex-flow: wrap;
}
.imagecards-block .cards-grid {
  width: 100%;
}
.imagecards-block .card {
  margin-bottom: 3rem;
}
.imagecards-block .card-link {
  display: block;
}
.imagecards-block .card-image {
  position: relative;
  overflow: hidden;
  width: 10rem;
  height: 10rem;
  margin: 0 auto;
  background-position: center;
  background-repeat: no-repeat !important;
  background-size: cover;
  border-radius: 50%;
}
.imagecards-block .card-title {
  display: inline-block;
  margin-top: 2rem;
  color: #333;
  font-family: 'Neue Haas Grotesk Medium', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 22px;
  font-weight: normal;
}
.imagecards-block > .card-link:hover .card-title {
  text-decoration: underline;
}
.center .card-title {
  text-align: center;
}
.left .card-title {
  text-align: left;
}
.right .card-title {
  text-align: right;
}
