.colored-cards-slider {
  position: relative;
  margin-bottom: 2em;
}
.colored-cards-slider .slick-slide {
  width: 250px;
}
.colored-cards-slider:before {
  top: 0;
  height: 110%;
  transform: none;
}
@media only screen and (max-width: 991px) {
  .colored-cards-slider {
    margin-right: -8.33333333%;
  }
}
.colored-cards:before,
.colored-cards-slider:before {
  position: absolute;
  top: 50%;
  right: 50%;
  left: 50%;
  width: 110vw !important;
  max-width: none !important;
  height: 90%;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  background-color: #ffffff;
  content: '';
  transform: translateY(-50%);
}
.colored-cards-slider:before {
  height: 100%;
}
.colored-cards-slider {
  padding: 1em 0 2.5em 0;
}
.colored-cards-slider .slick-track > .slick-slide:nth-child(6n + 1) .listing-card {
  background-color: #dfff83;
}
.colored-cards-slider .slick-track > .slick-slide:nth-child(6n + 1) .listing-card a {
  color: #231c1c;
}
.colored-cards-slider .slick-track > .slick-slide:nth-child(6n + 2) .listing-card {
  background-color: #cce7d2;
}
.colored-cards-slider .slick-track > .slick-slide:nth-child(6n + 2) .listing-card a {
  color: #9a1313;
}
.colored-cards-slider .slick-track > .slick-slide:nth-child(6n + 3) .listing-card {
  background-color: #f2eef8;
}
.colored-cards-slider .slick-track > .slick-slide:nth-child(6n + 3) .listing-card a {
  color: #655353;
}
.colored-cards-slider .slick-track > .slick-slide:nth-child(6n + 4) .listing-card {
  background-color: #ffde8a;
}
.colored-cards-slider .slick-track > .slick-slide:nth-child(6n + 4) .listing-card a {
  color: #6e2b4d;
}
.colored-cards-slider .slick-track > .slick-slide:nth-child(6n + 5) .listing-card {
  background-color: #ffceaa;
}
.colored-cards-slider .slick-track > .slick-slide:nth-child(6n + 5) .listing-card a {
  color: #2d1d1d;
}
.colored-cards-slider .slick-track > .slick-slide:nth-child(6n + 6) .listing-card {
  background-color: #f5ff83;
}
.colored-cards-slider .slick-track > .slick-slide:nth-child(6n + 6) .listing-card a {
  color: #9a1313;
}
#main .colored-cards > .ui.container {
  padding: 0 !important;
}
.colored-cards {
  position: relative;
}
.colored-cards .listing-column {
  position: relative;
}
.colored-cards .listing-column .default-card {
  min-height: 500px;
}
.colored-cards .masonry-grid_column .listing-column {
  margin-top: 120px;
  margin-bottom: 0;
}
.colored-cards .masonry-grid_column:first-child > .listing-column:nth-child(2n + 1) .listing-card {
  background-color: #dfff83;
}
.colored-cards .masonry-grid_column:first-child > .listing-column:nth-child(2n + 1) .listing-card a {
  color: #231c1c;
}
.colored-cards .masonry-grid_column:first-child .listing-column:first-child {
  margin-top: 0;
}
.colored-cards .masonry-grid_column:first-child > .listing-column:nth-child(2n + 2) .listing-card {
  background-color: #ffde8a;
}
.colored-cards .masonry-grid_column:first-child > .listing-column:nth-child(2n + 2) .listing-card a {
  color: #6e2b4d;
}
.colored-cards .masonry-grid_column:first-child .listing-column:not(:first-child) {
  margin-top: 120px;
}
.colored-cards .masonry-grid_column:nth-child(2) > .listing-column:nth-child(2n + 1) .listing-card {
  background-color: #cce7d2;
}
.colored-cards .masonry-grid_column:nth-child(2) > .listing-column:nth-child(2n + 1) .listing-card a {
  color: #9a1313;
}
.colored-cards .masonry-grid_column:nth-child(2) > .listing-column:nth-child(2n + 2) .listing-card {
  background-color: #ffceaa;
}
.colored-cards .masonry-grid_column:nth-child(2) > .listing-column:nth-child(2n + 2) .listing-card a {
  color: #2d1d1d;
}
.colored-cards .masonry-grid_column:nth-child(2) .listing-column:first-child {
  margin-top: 180px;
}
.colored-cards .masonry-grid_column:nth-child(3) > .listing-column:nth-child(2n + 1) .listing-card {
  background-color: #f2eef8;
}
.colored-cards .masonry-grid_column:nth-child(3) > .listing-column:nth-child(2n + 1) .listing-card a {
  color: #655353;
}
.colored-cards .masonry-grid_column:nth-child(3) > .listing-column:nth-child(2n + 2) .listing-card {
  background-color: #f5ff83;
}
.colored-cards .masonry-grid_column:nth-child(3) > .listing-column:nth-child(2n + 2) .listing-card a {
  color: #9a1313;
}
.colored-cards .masonry-grid_column:nth-child(3) .listing-column:first-child {
  margin-top: 40px;
}
body[data-theme='contrast-mode'] .colored-cards.card-listing .slick-slide .listing-card,
body[data-theme='contrast-mode'] .colored-cards.card-listing .listing-column .listing-card {
  background-color: #e9e9e9 !important;
}
body[data-theme='contrast-mode'] .colored-cards.card-listing .slick-slide .listing-card a,
body[data-theme='contrast-mode'] .colored-cards.card-listing .listing-column .listing-card a {
  color: #000000 !important;
}
