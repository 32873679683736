.agenda-listing .agenda-listing-content {
  display: flex;
  flex-direction: column;
}
.agenda-listing .agenda-listing-content .agenda-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
  gap: 2em;
}
.agenda-listing .agenda-listing-content .agenda-item-link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.2em;
}
.agenda-listing .agenda-listing-content .agenda-item-link:hover {
  text-decoration: none;
}
.agenda-listing .agenda-listing-content .agenda-item-link:hover .arrow.down:after {
  height: 15px;
}
.agenda-listing .agenda-listing-content .agenda-item-link:hover .agenda-title {
  text-decoration: underline;
}
.agenda-listing .agenda-listing-content .agenda-item {
  border-bottom: 1px solid var(--text-color);
}
.agenda-listing .agenda-listing-content .agenda-item:first-child {
  border-top: 1px solid var(--text-color);
}
.agenda-listing .agenda-listing-content .agenda-title {
  color: var(--text-color);
  font-size: 2rem;
}
.agenda-listing .agenda-listing-content .agenda-description {
  margin: 0;
  color: var(--text-color);
  font-size: 1.11111111rem;
  line-height: 1.3;
}
.agenda-listing .agenda-listing-content .agenda-right {
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: flex-end;
  color: var(--text-color);
  font-size: 1.11111111rem;
  text-align: right;
}
.agenda-listing .agenda-listing-content .agenda-right p {
  margin: 0;
  font-size: 1.11111111rem;
}
.agenda-listing .agenda-listing-content .arrow.down {
  position: relative;
  width: 16px;
  height: 16px;
  margin-top: auto;
  margin-bottom: 15px;
}
.agenda-listing .agenda-listing-content .arrow.down:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  border-width: 1px 1px 0 0;
  border-style: solid;
  border-color: var(--text-color);
  content: '';
  transform: rotate(90deg);
  transform-origin: 50% 50%;
}
.agenda-listing .agenda-listing-content .arrow.down:after {
  position: absolute;
  top: 16px;
  right: 0;
  left: auto;
  display: block;
  width: 0;
  height: 22px;
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: var(--text-color);
  content: '';
  transform: rotate(133deg);
  transform-origin: 100% 0;
  transition: height 0.5s ease;
}
@media only screen and (max-width: 767px) {
  .agenda-listing .agenda-listing-content .agenda-wrapper {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 1em;
  }
  .agenda-listing .agenda-listing-content .agenda-wrapper .agenda-right {
    align-items: flex-start;
    font-size: 0.77777778rem;
    text-align: unset;
  }
  .agenda-listing .agenda-listing-content .agenda-title {
    font-size: 1.33333333rem;
  }
  .agenda-listing .agenda-listing-content .agenda-description,
  .agenda-listing .agenda-listing-content .agenda-right p {
    font-size: 0.77777778rem;
  }
}
