.az-listing .az-listing-nav {
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
  text-transform: uppercase;
}
.az-listing .az-listing-nav a {
  font-size: 1.55555556rem;
}
@media only screen and (max-width: 991px) {
  .az-listing .az-listing-nav {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1em;
  }
  .az-listing .az-listing-nav a {
    font-size: 1.22222222rem;
  }
}
.az-listing .az-listing-content {
  max-width: 900px;
  margin-top: 5em;
}
.az-listing .az-listing-content .letter {
  text-transform: uppercase;
}
.az-listing .az-listing-content .az-list {
  padding: 0;
  margin: 0;
  margin-bottom: var(--space-xl);
}
.az-listing .az-listing-content .az-list li {
  padding: 5px 0;
  margin-bottom: 0;
  font-size: 1.55555556rem;
  line-height: 1.2em !important;
  list-style-type: none;
  text-transform: lowercase;
}
.az-listing .az-listing-content .az-list.multi-column {
  -moz-column-count: 3;
       column-count: 3;
  -moz-column-gap: 1em;
       column-gap: 1em;
}
.az-listing .az-listing-content .az-list.multi-column li {
  -moz-column-break-inside: avoid;
       break-inside: avoid-column;
}
@media only screen and (max-width: 991px) {
  .az-listing .az-listing-content .az-list.multi-column {
    -moz-column-count: 2;
         column-count: 2;
  }
}
@media only screen and (max-width: 767px) {
  .az-listing .az-listing-content .az-list.multi-column {
    -moz-column-count: 1;
         column-count: 1;
  }
}
.az-listing .az-listing-content .az-list.single-column li {
  width: calc(100% / 3);
}
@media only screen and (max-width: 991px) {
  .az-listing .az-listing-content .az-list.single-column li {
    width: calc(100% / 2);
  }
}
@media only screen and (max-width: 767px) {
  .az-listing .az-listing-content .az-list.single-column li {
    width: 100%;
  }
}
