.block.info-block {
  display: flex;
  width: 100%;
}
@media only screen and (min-width: 991px) {
  .block.info-block {
    justify-content: flex-end;
  }
}
.block.info-block .info-block-wrapper p {
  margin-bottom: 0;
}
@media only screen and (min-width: 991px) {
  .block.info-block .info-block-wrapper {
    max-width: 60%;
  }
}
.info-block-title {
  margin: 0;
}
.info-block-header {
  margin-bottom: var(--space-xs);
}
.info-block-header svg {
  margin-top: 10px;
}
.info-block-buttons,
.info-block-header {
  display: flex;
  align-items: flex-start;
  gap: 1em;
}
.info-block-buttons {
  margin-top: 2.5em;
}
