.image-album {
  position: relative;
}
.ui.modal.slider-modal {
  background: none !important;
  box-shadow: none;
}
.ui.modal.slider-modal .slick-slide > div {
  text-align: center;
}
.ui.modal.slider-modal .slick-slide > div img {
  margin: 0 auto;
}
.ui.modal.slider-modal > .content {
  background: none !important;
}
.slide-image-count {
  text-align: center;
}
.thumbnails {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  gap: 5px;
}
.image-album .preview-image-wrapper .ui.image {
  width: 482px;
  max-width: none;
  height: auto;
  margin-top: -30px;
  border-radius: 6px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.image-album .preview-image-wrapper .ui.image:hover {
  cursor: pointer;
}
.images-number {
  position: absolute;
  display: flex;
  width: 72px;
  height: 54px;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  color: #ffffff;
  pointer-events: none;
}
.images-number div {
  width: 100%;
  text-align: center;
}
.ui.image.img-thumb {
  width: 72px !important;
  height: 54px !important;
  border-radius: 6px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.ui.image.img-thumb:hover {
  cursor: pointer;
}
.modal-slide-img {
  width: auto !important;
  max-width: none;
  max-height: 800px !important;
}
.artwork-fallback-image {
  position: relative;
  width: 482px;
  height: 730px;
  padding: 1.5em;
  margin-top: -30px;
  background-color: var(--text-color);
  border-radius: 6px;
  color: var(--background-color);
}
.artwork-fallback-image .logo {
  text-align: right;
}
.artwork-fallback-image .item-meta {
  position: absolute;
  top: 50%;
  left: 1.5em;
  transform: translateY(-50%);
}
.artwork-fallback-image .item-meta .item-title {
  margin-bottom: 0;
}
@media only screen and (max-width: 991px) {
  .artwork-fallback-image {
    width: 100%;
    height: 490px;
  }
  .artwork-fallback-image .logo svg {
    display: inline-block;
    height: 112px !important;
  }
}
