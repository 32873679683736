body.homepage-view .blocks-wrapper .hero-unit-block {
  margin-right: -8.33333333% !important;
}
@media only screen and (max-width: 767px) {
  body.homepage-view .blocks-wrapper .hero-unit-block {
    margin-right: -25px !important;
  }
}
.hero-unit-block {
  margin-top: 3em;
  margin-bottom: 2em;
}
@media only screen and (max-width: 767px) {
  .hero-unit-block {
    margin-bottom: 0;
  }
}
.hero-unit-block .hero-unit-title {
  margin-bottom: var(--space-sm);
}
.hero-unit-block .hero-unit-content {
  position: absolute;
  bottom: 1.2em;
  left: 1.2em;
  display: inline-block;
  padding: 0.75em 1em;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 0.88888889rem;
}
.hero-unit-block .hero-unit-image {
  width: 100%;
  height: 100%;
  cursor: pointer;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  transform-origin: left bottom;
  transition: transform 0.5s ease;
}
.hero-unit-block .hero-unit-image-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
}
.hero-unit-block .hero-unit-image-wrapper iframe {
  border-radius: 10px;
}
.hero-unit-block .visible,
.hero-unit-block .hidden {
  display: flex;
}
.hero-unit-wrapper {
  position: relative;
  display: flex;
  gap: 2em;
}
.hero-unit-wrapper .logo,
.hero-unit-wrapper .logo svg {
  display: inline-block;
  height: 250px !important;
}
@media only screen and (max-width: 1080px) {
  .hero-unit-wrapper .logo,
  .hero-unit-wrapper .logo svg {
    height: 140px !important;
  }
}
@media only screen and (max-width: 480px) {
  .hero-unit-wrapper .logo,
  .hero-unit-wrapper .logo svg {
    height: 100px !important;
  }
}
.hero-unit-wrapper .hero-logo-wrapper {
  position: relative;
  margin-top: auto;
  direction: rtl;
}
.hero-unit-wrapper .hero-logo-wrapper .hidden {
  height: 0;
  visibility: hidden;
}
body.view-viewview .visible .logo svg {
  position: fixed;
  z-index: 10;
  pointer-events: none;
}
body.view-viewview .scroll-down .logo,
body.view-viewview .scroll-down .logo svg {
  transition: top 3s ease, height 1.5s ease;
}
body.view-viewview .scroll-up .hidden .logo,
body.view-viewview .scroll-down .hidden .logo,
body.view-viewview .scroll-up .hidden .logo svg,
body.view-viewview .scroll-down .hidden .logo svg {
  transition: top 3s ease, height 1.5s ease;
}
@media only screen and (min-width: 991px) {
  body.view-viewview .scroll-up .visible .logo,
  body.view-viewview .scroll-down .visible .logo,
  body.view-viewview .scroll-up .visible .logo svg,
  body.view-viewview .scroll-down .visible .logo svg {
    height: 100px !important;
  }
}
body.view-viewview .scroll-up.on-top .logo,
body.view-viewview .scroll-up.on-top .logo svg {
  height: 250px !important;
  transition: top 3s ease, height 1.5s ease;
}
@media only screen and (max-width: 1080px) {
  body.view-viewview .scroll-up.on-top .logo,
  body.view-viewview .scroll-up.on-top .logo svg {
    height: 140px !important;
  }
}
@media only screen and (max-width: 480px) {
  body.view-viewview .scroll-up.on-top .logo,
  body.view-viewview .scroll-up.on-top .logo svg {
    height: 100px !important;
  }
}
body.view-viewview .hero-unit-block.active .hero-unit-title {
  display: none;
}
body.view-viewview .hero-unit-block.active .logo,
body.view-viewview .hero-unit-block.active .logo svg {
  height: 100px !important;
  transition: top 3s ease, height 1.5s ease;
}
@media only screen and (min-width: 991px) {
  .hero-unit-image-wrapper.video {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
  }
  .hero-unit-image-wrapper.video:before {
    display: block;
    padding-top: 56.25%;
    content: '';
  }
  .hero-unit-image-wrapper.video iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
@media only screen and (max-width: 991px) {
  body.view-viewview .hero-unit-block.on-top .vissible .logo svg,
  body.view-viewview .hero-unit-block.on-top .logo svg {
    height: 140px !important;
  }
  body.view-viewview .hero-unit-block.active .hero-unit-wrapper .logo {
    top: auto;
  }
  body.view-viewview .hero-unit-block.active .hero-unit-wrapper .logo,
  body.view-viewview .hero-unit-block.active .hero-unit-wrapper .logo svg {
    height: 44px !important;
    margin-top: 0;
  }
  body.view-viewview .hero-unit-block.active .hero-unit-image-wrapper {
    width: 100% !important;
    height: 580px !important;
    transition: width 1.5s ease, height 1.5s ease !important;
  }
  body.view-viewview .scroll-down .vissible .logo svg,
  body.view-viewview .scroll-up .vissible .logo svg,
  body.view-viewview .scroll-up .vissible .logo svg,
  body.view-viewview .scroll-down .logo svg,
  body.view-viewview .scroll-up .logo svg,
  body.view-viewview .scroll-up .logo svg {
    height: 44px !important;
  }
  .hero-unit-block .hero-unit-image-wrapper {
    height: 380px;
    transition: width 1.5s ease, height 1.5s ease;
  }
  .hero-unit-image-wrapper.video {
    position: relative;
  }
  .hero-unit-image-wrapper.video iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 177.77777778vh !important;
    min-width: 100%;
    height: 56.25vw !important;
    min-height: 100%;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (max-width: 480px) {
  .hero-unit-block .hero-unit-image-wrapper {
    width: 227px !important;
    transition: width 1.5s ease, height 1.5s ease;
  }
  .hero-logo-wrapper .logo {
    position: relative;
    top: -60px !important;
  }
}
@media only screen and (max-width: 767px) {
  .blocks-wrapper .hero-unit-block ~ .block.listing {
    margin-top: var(--space-xs);
  }
  .hero-unit-wrapper {
    flex-direction: column;
    gap: 1em;
  }
  .hero-logo-wrapper .logo {
    position: relative;
    top: -60px !important;
  }
  .hero-unit-block .hero-unit-image-wrapper {
    width: calc(100% - 220px);
    height: 386px;
    transition: width 1.5s ease, height 1.5s ease;
  }
  .hero-unit-block.active .hero-unit-title {
    display: none;
  }
  .hero-unit-block.active .hero-logo-wrapper .logo {
    top: auto !important;
  }
  .hero-unit-block.on-top.active .hero-logo-wrapper .logo svg {
    height: 44px !important;
  }
  .hero-unit-block .hero-logo-wrapper .logo {
    position: relative;
    top: auto;
  }
}
