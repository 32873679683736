/* Enables customization of addons */
/* Helper to load variables */
#sidebar .image-wrapper {
  position: relative;
  height: 100px;
  margin-top: 1em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
}
#sidebar .image-wrapper .ui.button.remove-image {
  position: absolute;
  top: 7px;
  right: 7px;
  margin: 0;
  box-shadow: none;
}
#sidebar .image-wrapper .ui.button.remove-image:hover {
  background-color: transparent !important;
}
#sidebar .image-wrapper .ui.button.remove-image svg {
  padding: 2px;
  border: 1px solid #e4e8ec;
  margin: 0;
  background: #f3f5f7;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 8px;
  opacity: 1;
}
#sidebar .align-widget .eight.wide.column > div {
  display: flex !important;
  align-items: center;
}
#sidebar .align-widget .ui.icon.button {
  padding: 4px !important;
  margin-left: 4px !important;
  border-radius: 1px !important;
}
#sidebar .align-widget .ui.icon.button:hover {
  background-color: #edf1f2 !important;
}
#sidebar .align-widget .ui.buttons:first-child .ui.icon.button {
  margin-left: 0;
}
#sidebar .align-widget .ui.active.basic.icon.button {
  border: 1px solid #2996da;
  background: transparent !important;
}
.imagecards-carousel .slide-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0.4;
}
@media (max-width: 600px) {
  .imagecards-carousel .slide-overlay {
    background: #000;
  }
}
.imagecards-carousel .slider-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  height: 600px;
}
@media (max-width: 600px) {
  .imagecards-carousel .slider-wrapper {
    height: 450px;
  }
}
.imagecards-carousel .slider-wrapper .slider-slide .slide-img {
  height: 600px;
  background-position: center;
  background-size: cover;
}
.imagecards-carousel .slider-wrapper .slider-slide .slider-caption {
  position: absolute;
  right: 0;
  bottom: 36%;
  left: 0;
}
@media (max-width: 600px) {
  .imagecards-carousel .slider-wrapper .slider-slide .slider-caption {
    bottom: 40%;
  }
}
.imagecards-carousel .slider-wrapper .slider-slide .slide-body {
  color: #fff;
  text-align: left;
}
.imagecards-carousel .slider-wrapper .slider-slide .slide-body .slide-title {
  margin-bottom: 1rem;
  font-family: 'Neue Haas Grotesk Medium', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
}
@media (max-width: 768px) {
  .imagecards-carousel .slider-wrapper .slider-slide .slide-body .slide-title {
    margin-bottom: 2rem;
    font-size: 30px;
  }
}
.imagecards-carousel .slider-wrapper .slider-slide .slide-body .slide-description,
.imagecards-carousel .slider-wrapper .slider-slide .slide-body .slide-description > p {
  max-width: 750px;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
}
@media (max-width: 768px) {
  .imagecards-carousel .slider-wrapper .slider-slide .slide-body .slide-description,
  .imagecards-carousel .slider-wrapper .slider-slide .slide-body .slide-description > p {
    font-size: 24px;
  }
}
.imagecards-carousel .slider-wrapper .slider-slide .slide-body > a {
  color: #fff;
}
.imagecards-carousel .slider-wrapper .slider-slide .slide-copyright {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin-bottom: 0 !important;
  color: #fff;
  font-size: 11px !important;
  font-style: italic;
  opacity: 0.8;
  text-shadow: 1px 1px 1px #000;
}
.imagecards-carousel .slider-wrapper .slider-slide .slide-copyright a {
  color: #fff;
}
@media (max-width: 600px) {
  .imagecards-carousel .slider-wrapper .slider-slide .slide-copyright {
    bottom: 25%;
  }
}
.imagecards-carousel .image-gallery-slide-wrapper {
  position: relative;
}
.imagecards-carousel .image-gallery-custom-left-nav,
.imagecards-carousel .image-gallery-custom-right-nav {
  top: unset;
  bottom: -2.5rem;
}
@media screen and (max-width: 600px) {
  .imagecards-carousel .image-gallery-custom-left-nav,
  .imagecards-carousel .image-gallery-custom-right-nav {
    bottom: 100px !important;
  }
}
@media screen and (max-width: 480px) {
  .imagecards-carousel .image-gallery-custom-left-nav,
  .imagecards-carousel .image-gallery-custom-right-nav {
    bottom: 140px !important;
  }
}
.imagecards-carousel .image-gallery-fullscreen-button:hover::before,
.imagecards-carousel .image-gallery-custom-left-nav:hover::before,
.imagecards-carousel .image-gallery-play-button:hover::before,
.imagecards-carousel .image-gallery-custom-right-nav:hover::before {
  color: #fff;
}
.imagecards-carousel .image-gallery-slides {
  white-space: normal !important;
}
.imagecards-carousel .slider-slide {
  position: relative;
}
.imagecards-carousel .slider-arrow {
  position: absolute;
  right: 0;
  bottom: 23%;
  left: 0;
  margin-right: auto;
  margin-left: auto;
}
.imagecards-carousel .slider-arrow .left-arrow,
.imagecards-carousel .slider-arrow .right-arrow {
  position: relative;
  border: 0;
  margin-bottom: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  outline: none;
}
.imagecards-carousel .slider-arrow .left-arrow {
  left: -20px;
}
.imagecards-carousel .slider-arrow .right-arrow {
  left: -30px;
}
@media (max-width: 600px) {
  .imagecards-carousel .slider-arrow {
    bottom: 16%;
  }
}
.imagecards-carousel .full-width {
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw !important;
  max-width: initial !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
}
.imagecards-carousel .slick-arrow {
  display: none !important;
}
