.masonry-grid {
  display: flex;
  width: auto;
  margin-left: -30px;
}
.masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}
.masonry-grid_column > div {
  margin-bottom: 30px;
}
.artwork-card .card-title-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 0.5em;
  gap: 25px;
}
.artwork-card .artwork-creation {
  flex-shrink: 0;
  font-size: 0.88888889rem;
}
.artwork-card .listing-card.artwork-card {
  height: auto;
}
.artwork-card .listing-card.artwork-card .image-wrapper {
  margin-bottom: 0.8em;
}
.artwork-card .artwork-title {
  margin-bottom: 0.5em;
  overflow: hidden;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.artwork-card .author-name {
  font-size: 12px;
}
.block.search .search-input .divider {
  display: none;
}
.block.search .search-input .ui.input {
  border-bottom: 1px solid var(--text-color);
  color: var(--text-color);
  font-size: 1.55555556rem;
}
.block.search .search-input .ui.input input,
.block.search .search-input .ui.input input:active,
.block.search .search-input .ui.input input:focus {
  border: none;
  background-color: transparent;
  color: var(--text-color) !important;
}
.block.search .search-input .ui.input input::-moz-placeholder, .block.search .search-input .ui.input input:active::-moz-placeholder, .block.search .search-input .ui.input input:focus::-moz-placeholder {
  color: var(--text-color) !important;
  opacity: 0.5;
}
.block.search .search-input .ui.input input::placeholder,
.block.search .search-input .ui.input input:active::placeholder,
.block.search .search-input .ui.input input:focus::placeholder {
  color: var(--text-color) !important;
  opacity: 0.5;
}
