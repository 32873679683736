.rsbtn {
  position: absolute !important;
}
.rsbtn_text::after,
.rsbtn_text::before {
  right: 0;
  left: auto !important;
}
.rsbtn_text span {
  padding-right: 2em !important;
  padding-left: 0% !important;
  text-align: center !important;
  vertical-align: center !important;
  height: auto !important;
  line-height: 13px;
  margin-top: 7.5px !important;
  margin-bottom: 7.5px !important;
}
.rsbtn_left.rsimg {
  padding-right: 15px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.rsbtn_play,
.rsbtn_exp,
.rsbtn_exp_inner {
  height: 40px !important;
}
.rsbtn_tools {
  margin-top: 12px !important;
}
.rsbtn_exp {
  min-width: 500px !important;
}
.rsbtn_dropdown {
  top: 2.5em !important;
}
