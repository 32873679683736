.image-carousel {
  position: relative;
  margin: 2em 0;
}
.image-carousel .thumbnail-slider-wrap {
  position: relative;
}
.image-carousel .thumbnail-slider-wrap .slick-slide {
  margin-top: 15px !important;
  margin-right: 15px !important;
}
.image-carousel .thumbnail-slider-wrap .slick-track {
  margin-right: 0px !important;
  margin-left: auto !important;
}
.image-carousel .thumbnail-slider-wrap .slick-slide,
.image-carousel .thumbnail-slider-wrap .slide-img {
  height: 54px !important;
}
.image-carousel .slick-track > .slick-slide:last-child {
  position: absolute;
  top: 15px;
  right: 13px;
  width: 87px;
  margin: 0 !important;
}
.image-carousel .total {
  height: 54px;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  pointer-events: none;
}
.image-carousel .total span {
  margin: 0 auto;
  color: #ffffff;
  font-size: 20px;
}
.image-carousel .slick-dots {
  bottom: 15px;
}
.image-carousel .slick-dots li {
  width: 16px;
  height: 16px;
}
.image-carousel .slick-dots li button:before {
  color: var(--background-color);
  font-size: 0.88888889rem;
  opacity: 1;
}
.image-carousel .slick-dots li.slick-active button:before {
  color: var(--text-color);
  opacity: 1;
}
.image-carousel .slick-track {
  display: flex !important;
}
.image-carousel .slick-slide {
  height: inherit !important;
  padding: 1rem !important;
}
.image-carousel .slick-slide .ui.card {
  width: 100%;
  height: 100%;
}
.image-carousel .slick-slide > div {
  height: 100%;
}
.image-carousel .slide-img {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  cursor: grab;
}
.image-carousel .slide-img:after {
  z-index: 1;
}
.image-carousel .slide-img a {
  display: inline-block;
  width: 100%;
}
.image-carousel .slide-img .ui.placeholder {
  width: 100%;
  max-width: none;
  height: 100%;
  min-height: 250px;
}
.image-carousel .slide-img .card-link {
  height: 100%;
}
.image-carousel .slide-img a:not(.card-link) {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
.image-carousel .slide-img img.bg-image {
  width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: 6px;
  -o-object-fit: cover;
     object-fit: cover;
}
.slide-caption > p:first-child {
  margin-top: 1.5em !important;
}
.slide-caption p {
  margin-bottom: 1em !important;
  color: var(--text-color);
  font-size: 0.77777778rem;
}
.big-carousel .slick-slide {
  padding: 0 !important;
}
