/*******************************
      Slider Listing
*******************************/
.slider-listing .slick-slide.slick-active:last-child {
  margin-right: 0em;
}
.block.listing.slider_listing .pagination-wrapper {
  display: none;
}
.block.listing .headline {
  display: none;
}
.block.listing .link-more {
  text-align: right;
}
.slider-listing .slick-list {
  margin: 0 -10px !important;
}
.slider-listing .slick-slide {
  padding: 10px !important;
}
.slider-listing .slick-dots li {
  width: 16px;
  height: 16px;
}
.slider-listing .slick-dots li button:before {
  color: var(--text-color);
  font-size: 0.88888889rem;
}
.slider-listing .slick-dots li.slick-active button:before {
  color: var(--text-color);
  opacity: 1;
}
.slider-carousel-container {
  margin: 3em 0;
}
