.toggle-switch {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 12px;
}
.toggle-switch input[type='checkbox'] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--text-color);
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  top: 2px;
  left: 3px;
  width: 8px;
  height: 8px;
  background-color: var(--background-color);
  border-radius: 50%;
  content: '';
  transition: transform 0.3s ease;
}
.toggle-switch input[type='checkbox']:checked + .switch::before {
  background-color: #fff;
  transform: translateX(6px);
}
.toggle-switch input[type='checkbox']:checked + .switch {
  background-color: #000;
}
.contrast-toggle {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
