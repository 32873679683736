.logo-carousel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: var(--space-xs);
  margin-bottom: var(--space-md);
}
.logo-carousel .svg-wrapper {
  height: 100%;
}
.logo-carousel .svg-wrapper > div {
  height: 100%;
}
.logo-carousel .svg-wrapper svg {
  display: block;
  width: auto !important;
  height: 100% !important;
  fill: var(--text-color);
}
.logo-carousel .ui.placeholder {
  width: 100%;
  min-width: 60px;
  height: 100%;
  min-height: 60px;
}
.logo-carousel .logo-slide-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1em;
}
.logo-carousel .logo-slide-img a {
  height: 100%;
}
.logo-carousel .logo-slide-img img.bg-image {
  max-height: 100%;
  margin: auto auto;
  -o-object-fit: contain;
     object-fit: contain;
}
@media only screen and (max-width: 480px) {
  .logo-carousel {
    align-items: flex-start;
  }
  .logo-carousel .logo-slide-img {
    margin-left: 0;
    height: 30px !important;
  }
}
