.block.quoteBlock,
.block.quoteBlock-wrapper {
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);
}
@media only screen and (max-width: 991px) {
  .block.quoteBlock,
  .block.quoteBlock-wrapper {
    margin: var(--space-lg) 0;
  }
}
.block.quoteBlock .icon-wrapper,
.block.quoteBlock-wrapper .icon-wrapper {
  text-align: center;
}
.block.quoteBlock .icon-wrapper.top,
.block.quoteBlock-wrapper .icon-wrapper.top {
  border-top: 1px solid var(--text-color);
}
.block.quoteBlock .icon-wrapper.bottom,
.block.quoteBlock-wrapper .icon-wrapper.bottom {
  border-bottom: 1px solid var(--text-color);
}
.block.quoteBlock p,
.block.quoteBlock-wrapper p {
  margin: 0;
  font-size: 2.16666667rem !important;
  line-height: 1.1 !important;
}
@media only screen and (max-width: 991px) {
  .block.quoteBlock p,
  .block.quoteBlock-wrapper p {
    font-size: 1.44444444rem !important;
    line-height: 1.1 !important;
  }
}
